import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import SpotifyWebApi from "spotify-web-api-js";

const spotifyApi = new SpotifyWebApi();

const getTokenFromUrl = () => {
  console.log(window.location.href)
  return window.location.hash
    .substring(1)
    .split("&")
    .reduce((initial, item) => {
      let parts = item.split("=");
      initial[parts[0]] = decodeURIComponent(parts[1]);
      return initial;
    }, {});
};

function App() {
  const [spotifyToken, setSpotifyToken] = useState("");
  const [nowPlaying, setNowPlaying] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [artists, setArtists] = useState([]);
  const [allGenres, setAllGenres] = useState([]);
  const [userGenres, setUserGenres] = useState([]);
  const [availableGenres, setAvailableGenres] = useState([]);
  const [chosenGenre, setChosenGenre] = useState([]);
  const [topGenres, setTopGenres] = useState([]);
  const [recSongs, setRecSongs] = useState([]);
  const [status, setStatus] = useState(undefined);
  const [renderStatus, setRenderStatus] = useState(undefined);
  const [user, setUser] = useState([]);
  const [profileStatus, setProfileStatus] = useState(undefined);
  const [playlistURL, setPlaylistURL] = useState(undefined);
  //const [topTracks, setTopTracks] = useState(new Map());

  useEffect(() => {
    //console.log("from url: ", getTokenFromUrl());
    const spotifyToken = getTokenFromUrl().access_token;
    window.location.hash = "";

    if (spotifyToken) {
      setSpotifyToken(spotifyToken);
      spotifyApi.setAccessToken(spotifyToken);
      getAvailableGenres();
      getTopArtists();
      getProfile();
      setLoggedIn(true);
      console.log("a")
    }
  });

  {/*const getNowPlaying = () => {
    spotifyApi.getMyCurrentPlaybackState().then((response) => {
      console.log(response);
      setNowPlaying({
        name: response.item.name,
        albumArt: response.item.album.images[0].url,
      });
    });
  };*/}

  function getProfile() {
    spotifyApi.getMe().then((response) => {
      setUser(response);
      setProfileStatus({type: 'success'})
    })
  }

  function renderProfile(){
    return <img className="rounded-full inline object-cover w-16 h-16 hover:p-0.5 border-4 border-gray-dark" alt="profile picture" src={user.images[0].url}></img>
  }

  const getTopArtists = () => {
   spotifyApi.getMyTopArtists({
    time_range: "short_term",
    limit: 5,
  }).then((response) => {
    setArtists(response.items)
   /* console.log(artists[0].name);*/ 
    }).catch((error) => {
      console.log(error);
    });
  }

  var count = function(ary, classifier) {
    return ary.reduce(function(counter, item) {
      var p = (classifier || String)(item);
      counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
      return counter;
    }, {})
  }

  const getAvailableGenres = () => {
    setRenderStatus({type: 'fail'})
    setStatus({type: 'error'})
    setRecSongs([]);
    // get max top artists, add all genres together in array
    spotifyApi.getAvailableGenreSeeds().then((response) => {
       setAllGenres(response.genres);
    }).catch((error) => {
      window.location.reload();
    })
    spotifyApi.getMyTopArtists({
      time_range: "short_term",
      limit: 50,
    }).then((response) => {
    var data = [];
    for (let i = 0; i < response.items.length; i++) {
      for (let j = 0; j < response.items[i].genres.length; j++) {
        data.push(response.items[i].genres[j])
        }
      };
      var result = Object.keys(count(data)).sort(function(a, b) {
        return count(data)[b] - count(data)[a];
      })
      var topGenres = [];
      for(let k=0; k < 5; k++){
        topGenres.push(result[k]);
      }
      const userGenres = Array.from(new Set(result));
      var userGenresMax = [];
      if (userGenres.length>126){
        for(let k=0; k < 100; k++){
          userGenresMax.push(userGenres[k]);
        }
        userGenres = userGenresMax;
      }
      
      var availableGenres = allGenres.filter(item => !userGenres.includes(item));

      setTopGenres(topGenres);
      setAvailableGenres(availableGenres);
      setUserGenres(userGenres);
      var item = availableGenres[Math.floor(Math.random()*availableGenres.length)];
      setChosenGenre(item);
    }).catch((error) => {
      console.log(error);
      window.location.reload();
    });

    // filter all available - played genres
  }

  const getRecommendation = () => {
    // get recommendation from random genre
    spotifyApi.getRecommendations({
      seed_genres: chosenGenre,
      limit: 30,
      max_popularity: 50,
    }).then((response) => {
      console.log(response.tracks[0].artists[0].name);
      setRecSongs(response.tracks);
      setRenderStatus({type: 'success'})
    }).catch((error) => {
      console.log(error);
      setRenderStatus({type: 'fail'})
      //window.location.reload();
    });
    console.log(chosenGenre);
  }


  const generatePlaylist = () => {
    if(recSongs[0]==null){
      return;
    }
    spotifyApi.getMe().then((response) => {
     // var playlistId = "";
      spotifyApi.createPlaylist(response.id, 
        { 'name': 'Recommended tracks for ' + chosenGenre, 'description': 'Generated from Expand Your Music', 'public': true })
      .then((res) => {
        setPlaylistURL(res.external_urls.spotify);
        const uris = [];
        for(let i = 0; i<recSongs.length; i++){
          uris.push(recSongs[i].uri);
        }
        console.log(uris);
        spotifyApi.addTracksToPlaylist(res.id, uris).then(() => {
          setStatus({ type: 'success' });
        })
        .catch((error) => {
          setStatus({ type: 'error', error });
         // window.location.reload();
        });
      });
    });
  }

  function renderPlaylist() {
    return (
      <>
      <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-left inline-block min-w-fit sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-fit divide-y divide-gray-200">
              <thead className="bg-gray">
                <tr>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-medium text-white uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-left text-sm font-medium text-white uppercase tracking-wider"
                  >
                    Artist
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray divide-y divide-gray-200 text-left">
                {recSongs.map(song => (
                  <tr key={song.name}>
                    <td className="px-4 py-4 whitespace-nowrap">
                        <a href={song.external_urls.spotify} className="text-sm font-medium text-white underline underline-offset-4">{song.name}</a>
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap">
                      <a href={song.artists[0].external_urls.spotify} className="text-sm text-white underline underline-offset-4">{song.artists[0].name}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
      </>
    )
  }

  const LOGIN_URI =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:8888/login'
    : 'https://expand-your-music-45acdbc69625.herokuapp.com/login';


  const login = () => {
    console.log(LOGIN_URI)
    window.location.href = LOGIN_URI
  }

  const logout = () => {
    window.localStorage.removeItem("spotifyToken");
    setSpotifyToken("");
    setLoggedIn(false);
}

  return (
    <body className="App">
       {!loggedIn && 
      <>
      <div class="h-screen flex justify-center items-center">
        <div className="box-border p-4 border-1 shadow-xl">
        <div className="text-xl font-semibold">Expand Your Music</div>
        <div className = "text-gray-dark font-light text-lg mb-0">Expand Your Music analyzes your top genres and finds new genres for you to explore.</div>
         <div className = "text-gray-dark font-light text-lg mt-0"> To get started, log in with your Spotify credentials below.</div>
        <button class="mr-2 bg-green text-white py-2 px-6 rounded-full" onClick={login}>Log into Spotify</button>
        {/*<a href="http://localhost:8888"><button class="mr-2 bg-green text-white py-2 px-6 rounded-full">Log into Spotify</button></a>*/}
      </div>
      </div>
      </>
      }

      <body class = "bg-gray-dark">
      {loggedIn && (
        <>
        
        {/*<div className="bg-gray-light">*/}

        <div className="flex justify-center mt-0">
        <header className="w-10/12 h-70 bg-gray-dark rounded-xl flex justify-center items-center flex-col">
        <h1 className = "text-white text-4xl mb-5 mt-20 font-semibold">Expand Your Music</h1>
        <div className="text-white text-2xl mb-10 font-light">Welcome to Expand Your Music! Check out your Spotify stats for this last month below. </div>
        </header>
        </div>

           {/* <aside class="fixed top-0 left-0 w-32 h-full" aria-label="Sidenav">
          <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800 dark:border-gray-700">
          </div>
      </aside>*/}

        <div className = "grid grid-cols-2 space-x-4 w-fit min-w-fit justify-center items-stretch m-auto overflow-auto">

          <div class="grid">
          <h1 className="text-white text-xl underline underline-offset-8 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-30 duration-150 ">Your Top Artists</h1>
          {artists.map(artist => (
            <>
            <div className="mx-auto flex flex-1 flex-col overflow-y-auto text-white text-lg">
              <div style={{opacity: "1", transform: "none"}}>
              <div class="flex flex-row space-x-4 items-center px-6 py-4 hover:bg-gray-50 group transition-all border border-gray-400 justify-center">
                <div class="shadow-md group-hover:shadow-xl rounded hidden md:inline">
                <img alt={artist.name} layout="fill" src={artist.images[0].url} class="object-cover h-14 w-14 rounded hidden md:inline" loading="lazy"></img>
                </div>
                <div class="flex flex-col min-w-0 items-center">
                  <div class="flex flex-row items-center">
                    <a href={artist.external_urls.spotify} class="text-white font-medium underline underline-offset-4">{artist.name}</a>
                    </div>
                <p class="text-white text-sm">
                <span class="flex flex-row flex-wrap">
                <span class="rounded px-2 py-0.5 mr-2 mt-1 text-white border border-black text-sm">{artist.genres[0]}</span>
                </span>
                </p>
                </div>
                </div>
                </div>
              </div>
              </>
          ))}
          </div>

          <div class="grid">
          <h1 className="text-white text-xl underline underline-offset-8 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-30 duration-150">Your Top Genres</h1>
          {topGenres.map(genre => (
          <>
    
          <div className="mx-auto flex flex-1 flex-col overflow-y-auto text-white text-lg">
              <div style={{opacity: "1", transform: "none"}}>
              <div class="flex flex-row space-x-4 items-center px-3 py-4 hover:bg-gray-50 group transition-all border border-gray-400">
              <div class="items-center">
                <div class="text-white text-lg">
                <div class="flex flex-col min-w-0 items-center">
                  <div class="flex flex-row items-center">
                  <p class="text-white font-medium">
                  {genre}</p>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                </div>
          </>
          ))}
          </div>
          </div>
         {/* </div>*/}
        </>
      )}
      {loggedIn && (
        <>
        <div className="text-white text-lg font-light">Ready to expand your music? Click to randomly select a new genre to explore!</div>
        <button class="mr-2 bg-green text-white py-2 px-6 rounded-full" onClick={getAvailableGenres}>Explore New Genre</button>
        {<div className="text-white text-lg font-light">Genre: {chosenGenre}</div>}
        </>
      )}
      {loggedIn && (
        <>
        <div className = "recSection">
        <button class="mr-2 bg-green text-white py-2 px-6 rounded-full" onClick={getRecommendation}>Generate Tracks</button>
        <button class="mr-2 bg-green text-white py-2 px-6 rounded-full" onClick={generatePlaylist}>Save to Spotify</button>
        <div>
        {status?.type === 'success' && <a href={playlistURL} class="text-white my-3 font-light underline underline-offset-8 transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-30 duration-150">Successfully created playlist!</a>}
        {renderStatus?.type === 'success' && renderPlaylist()}
          {renderStatus?.type === 'fail' && <></>}
          {status?.type === 'error' && <></>}
          </div>
      </div>
      </>
      )}

      {loggedIn && (
        <>
        <footer class="w-full h-60 bg-gray static bottom-0 flex flex-col items-center justify-center my-10 mt-15 mb-0 ">
          <div class="flex flex-row items-center justify-center">
            {profileStatus?.type === 'success' && renderProfile()}
            <h1 class="text-white mx-3 text-lg font-light">Signed in as {user.display_name}</h1>
          </div>
        <button className="mr-2 bg-green text-white py-2 px-6 rounded-full" onClick={logout}>Logout</button>
        </footer>
        </>
      )}
      </body>
    </body> 
  );
}

export default App;
